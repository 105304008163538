import BaseEnum from "./BaseEnum";
const JobApplicationStatusEnum = new BaseEnum(
  [
    "started-application", //fr
    "in-progress", //fr
    "under-review", //fr
    "outscal-initiated-introduction", //fr
    "candidate-rejected-company-on-screening", //fr
    "introduced", //admin
    "rejected", //admin
    "passed", //admin
    "asked-to-opt-in", //admin
    "opt-in-introduced", //admin
    "opt-in-rejected", //admin
    "assessment-pending", //admin
    "candidate-not-matched", //admin
    "candidate-interest-awaited", //admin
    "company-in-progress", // b2b
    "company-rejected-candidate-on-screening", //b2b
    "company-on-hold", //b2b
    "viewed", //not used
    "company-hired", // b2b
    "company-init-shortlisted", // b2b
    "company-init-candidate-accepted", // b2b
    "company-init-candidate-rejected", // b2b
    "job-shortlisted",
    "job-unshortlisted",
  ],
  [
    "started-application", //fr
    "in-progress", //fr
    "under-review", //fr
    "outscal-initiated-introduction", //fr
    "candidate-rejected-company-on-screening", //fr
    "introduced", //admin
    "rejected", //admin
    "passed", //admin
    "asked-to-opt-in", //admin
    "opt-in-introduced", //admin
    "opt-in-rejected", //admin
    "assessment-pending", //admin
    "candidate-not-matched", //admin
    "candidate-interest-awaited", //admin
    "company-in-progress", // b2b
    "company-rejected-candidate-on-screening", //b2b
    "company-on-hold", //b2b
    "viewed", //not used
    "company-hired", // b2b
    "company-init-shortlisted", // b2b
    "company-init-candidate-accepted", // b2b
    "company-init-candidate-rejected", // b2b
    "job-shortlisted",
    "job-unshortlisted",
  ]
);
export default JobApplicationStatusEnum;
