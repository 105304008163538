import { gql } from "@apollo/client";

const SHORTLIST_JOB = gql`
  mutation ShortlistJob($job_id: String!, $status: Int!) {
    shortlistJob(job_id: $job_id, status: $status) {
      user_id
      job_id
      author
      adminAuthor
      status {
        history {
          status
          createdAt
          updatedAt
        }
        currentStatus {
          status
          createdAt
          updatedAt
        }
      }
      report_status
      shortlistedStatus {
        history {
          status
          createdAt
          updatedAt
        }
        currentStatus {
          status
          createdAt
          updatedAt
        }
      }
      job_apply_form_id
      createdAt
      updatedAt
    }
  }
`;

export { SHORTLIST_JOB };
