import * as Yup from "yup";
const {
    SELECT,
    RADIO,
    TEXT,
} = require("@/outscal-commons-frontend/FormBuilder/InputTypes");

export const plugData = {
    analyticsIntentText: "EXPERIMENT_job_questions",
    heading: "Not hearing back from companies?",
    subHeading:
        "Unlock the secrets to a successful job application and accelerate your journey to your next opportunity.",
    image:
        "https://outscal-assets.s3.ap-south-1.amazonaws.com/orange-mentoring.png",
    ctaText: "Take the quiz now!",
    pro: {
        heading: "Not hearing back from companies?",
        subHeading:
            "Diagnose problems in your job applications and land your next job faster.",
        ctaText: "Take the quiz now!",
    },
};

export const jobQuestionsSchema = {
    work_experience: {
        heading:
            "Which one of the following describes your professional work experience?",
        inputType: RADIO,
        validation: Yup.string().required("Required*"),
        optionsList: [
            {
                id: "fresher",
                label:
                    "I am a student/fresher looking for my first job in the gaming industry",
            },
            {
                id: "full-time-gaming-industry",
                label: "I have worked full-time in the gaming industry",
            },
            {
                id: "freelancer-gaming-industry",
                label: "I am a freelancer in the gaming industry",
            },
            {
                id: "full-time-non-gaming-industry",
                label:
                    "I am a working professional, but I am not from the gaming industry",
            },
        ],
    },
    desired_role: {
        heading: "What role are you applying for in the industry?",
        inputType: SELECT,
        validation: Yup.object().required("Required*"),
        dependancy: (formValues) => {
            return formValues?.work_experience;
        },
        optionsList: [
            {
                id: "programming",
                label: "Programming",
            },
            {
                id: "design",
                label: "Design",
            },
            {
                id: "art",
                label: "Art",
            },
            {
                id: "qa",
                label: "QA",
            },
            {
                id: "production-project-management",
                label: "Production/Project Management",
            },
            {
                id: "product-management",
                label: "Product Management",
            },
            {
                id: "other",
                label: "Other",
            },
        ],
    },
    skill_self_rating: {
        type: "number",
        dependancy: (formValues) => {
            return formValues?.desired_role?.id;
        },
        heading: `Rate your relevant skills on a scale of 1 to 10`,
        validation: Yup.number()
            .required("Required*")
            .min(1, "Must be at least 1")
            .max(10, "Must be at most 10"),
        inputType: TEXT,
    },
    has_portfolio: {
        heading: "Do you have a game project portfolio?",
        inputType: RADIO,
        defaultValue: "",
        validation: Yup.string().required("Required*"),
        dependancy: (formValues) => {
            return formValues?.skill_self_rating;
        },
        optionsList: [
            {
                id: "updated-portfolio-website",
                label: "Yes, I have an up-to-date portfolio and a website",
            },
            {
                id: "social-portfolio",
                label: "Yes, I have a portfolio on itch/github/gdrive but no website",
            },
            {
                id: "stale-portfolio",
                label: "Yes, but it has not been updated",
            },
            {
                id: "private-portfolio",
                label: "Yes, but I cannot share a lot of projects that I worked on",
            },
            {
                id: "no-portfolio",
                label: "No, I don’t have a portfolio",
            },
        ],
    },
    resume_feedback: {
        heading: "Have you received any feedback on your portfolio/resume?",
        inputType: RADIO,
        defaultValue: "",
        validation: Yup.string().required("Required*"),
        dependancy: (formValues) => {
            return formValues?.has_portfolio;
        },
        optionsList: [
            {
                id: "positive",
                label: "Yes, it was helpful and I have modified it",
            },
            {
                id: "negative",
                label: "Yes, but it was very generic",
            },
            {
                id: "NA",
                label: "No, I have not gotten my feedback",
            },
        ],
    },
    been_laid_off: {
        dependancy: (formValues) => {
            return formValues?.work_experience == "full-time-gaming-industry";
        },
        heading: "Have you been impacted by layoffs in the industry?",
        inputType: RADIO,
        defaultValue: "",
        validation: Yup.string().required("Required*"),
        optionsList: [
            {
                id: true,
                label: "Yes",
            },
            {
                id: false,
                label: "No",
            },
        ],
    },
};
