import { getUpdatedJobSlug } from "@/utils/urlHelper";
import { GET_JOB_BY_SLUG } from "@/Logic/GraphQL/Queries/Jobs.query";
import ApolloClientManager from "@/Logic/GraphQL/ApolloClientManager";
import { SHORTLIST_JOB } from "@/Logic/GraphQL/Mutations/UserJobApplications.mutation";
import { JobApplicationStatusEnum } from "@/outscal-commons-frontend/Enums";

class JobDetailsDataController {
  constructor() {
    this.apolloClient = ApolloClientManager.client;
  }

  async getJob({ jobSlug }) {
    const data = await this.apolloClient.query({
      query: GET_JOB_BY_SLUG,
      variables: {
        slug: jobSlug,
      },
    });

    return {
      jobDetails: data?.data?.job,
      similarJobs: data?.data?.job?.similar_jobs,
      loading: data?.loading,
    };
  }

  async shortlistJob({ user_id, job_id, status }) {
    try {
      const data = await this.apolloClient.mutate({
        mutation: SHORTLIST_JOB,
        variables: {
          job_id,
          status: JobApplicationStatusEnum.getValue(status),
        },
      });
      return data;
    } catch (error) {
      console.error("Error in shortlistJob:", error);
      throw error;
    }
  }

  async getJobDetails({ jobSlug }) {
    try {
      let job = await this.getJob({ jobSlug });
      return job;
    } catch (err) {
      console.log(err);
    }
  }
}

export default JobDetailsDataController;
