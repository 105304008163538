import React from "react";
import styles from "./ImageTextCardD.module.css";
import Image from "next/image";
import { UIElements } from "@/outscal-commons-frontend/Hooks/useAnalyticsClickEvent";
import { useRouter } from "next/router";
import ConditionLinkView from "@/mvcComponents/v2/Commons/UtilityComponents/ConditionLink.view";
import { themeColorsMap } from "@/outscal-commons-frontend/utils/commons";

const ImageTextCardD = ({
  image = {},
  mobileCardImage = {},
  title = "",
  description = "",
  link = "",
  analyticsName = "",
  ctaText = "",
  theme = "yellow",
}) => {
  const router = useRouter();

  return (
    <div
      className={`${styles.root} ${link ? styles.pointer : ""}`}
      id={analyticsName}
      onClick={(e) => {
        e.preventDefault();
        if (link) {
          router.push(link, undefined, { shallow: true });
        }
      }}
      data-analytics={[UIElements.CARD, `${analyticsName}`]}
      view-analytics={[UIElements.CARD, `${analyticsName}`]}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        width: "100%",
      }}
    >
      <div
        className={styles.cardDetailsWrapper}
        style={{
          border: `1.4px solid ${themeColorsMap[theme]?.textColor}`,
          backgroundColor: themeColorsMap[theme]?.bgColor,
        }}
      >
        {image?.url && (
          <Image
            width={160}
            height={96}
            className={styles.cardImg}
            src={image.url}
            alt={title}
          />
        )}
        {(image?.url || mobileCardImage?.url) && (
          <Image
            width={160}
            height={96}
            className={styles.mobileCardImage}
            src={mobileCardImage.url || image.url}
            alt={title}
          />
        )}
        <div className={styles.content}>
          <div>
              <p
                className={`${styles.title} ${styles["trunc-2"]}`}
                style={{ color: themeColorsMap[theme]?.textColor }}
              >
                {title}
              </p>
            {description && (
              <div className={`${styles.description} ${styles["trunc-2"]}`}>
                {description}
              </div>
            )}
          </div>
          {ctaText && link && (
            <p
              className={styles.ctaButton}
              data-analytics={[UIElements.BUTTON, `${analyticsName}_cta`]}
              onClick={(e) => {
                e.stopPropagation();
                if (link) {
                  router.push(link, undefined, { shallow: true });
                }
              }}
            >
              {ctaText}
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default ImageTextCardD;
