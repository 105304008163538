import Constant from "@/staticData/constant";
import JobListDataController from "./JobListData.controller";
import JobDetailsDataController from "./JobDetailsData.controller";
import JobFiltersDataController from "./JobFiltersData.controller";
import LazySingleton from "@/utils/DesignPatternHelpers/LazySingleton";
import JobsDataModel from "./JobsData.model";

let wasInitializedOnce = false;
class JobsSectionService extends LazySingleton {
  constructor() {
    super();
    this.wasInitialisedBefore = false;
    this.initialized = false;
    this.data = new JobsDataModel();
    this.router = null;
    this.PAGE_LIMIT = Constant.LOGGED_IN_PAGE_LIMIT;
    this.canShowLoading = true;
    setTimeout(() => {
      this.canShowLoading = false;
      this.jobListDataController.checkedIfNeedToAddSimilarJobs = true;
    }, 700);
    this.jobListDataController = new JobListDataController(this);
    this.jobDetailsDataController = new JobDetailsDataController(this);
    this.jobFiltersDataController = new JobFiltersDataController(this);

    this.detailsLoading = null;
    this.listLoading = null;
    this.filtersLoading = null;

    this.setListLoadingFunction = null;
    this.setDetailsLoadingFunction = null;
    this.setFiltersLoadingFunction = null;
    this.setHideHeaderFunction = null;

    this.directionChangeTimer = null;
    this.lastDirection = null;
  }

  setupData(
    defaultJobs,
    selectedJobData,
    exploreMoreList,
    totalJobs,
    inter_linkings,
    hiddenFilters = {},
    defaultFilters = {},
    setList = true,
    assessmentsStrapiList = [],
    assessmentsDbList = [],
    jobResources
  ) {
    if (!this.initialized) {
      this.initialized = true;
      this.data = new JobsDataModel();
      this.jobListDataController = new JobListDataController(this);
      this.jobDetailsDataController = new JobDetailsDataController();
      this.jobFiltersDataController = new JobFiltersDataController(this);
      this.data.isCompanyPage =
        typeof window !== "undefined" &&
        window.location.href.includes("/company/");
      this.data.jobsListData = {
        ...this.data.jobsListData,
        totalJobs,
        defaultJobsCount: totalJobs,
        interLinkings: inter_linkings,
        jobPresets: exploreMoreList,
        canLoadMoreJobs: defaultJobs.length < totalJobs,
        page: 0,
      };
      this.data.jobsFilterData = {
        ...this.data.jobsFilterData,
        appliedFilters: {},
        hiddenFilters,
      };
      this.data.jobsPlugData = {
        ...this.data.jobsPlugData,
        assessmentsStrapiList,
        assessmentsDbList,
        jobResources,
      };

      if (setList) {
        if (wasInitializedOnce) {
          this.setDetailsLoading(true);
          this.setListLoading(true);
          this.addJobsToList(defaultJobs, undefined, true);
          this.showJobDetails(selectedJobData.jobDetails);
          this.setDetailsLoading(false);
          this.setListLoading(false);
        } else {
          this.addJobsToList(defaultJobs, undefined, true);
          this.showJobDetails(selectedJobData.jobDetails);
        }
      }
    }
    wasInitializedOnce = true;
  }

  setupStates(
    detailsLoading,
    listLoading,
    filtersLoading,
    setListLoading,
    setDetailsLoading,
    setFiltersLoading,
    setHideHeader,
    router
  ) {
    this.detailsLoading = detailsLoading;
    this.listLoading = listLoading;
    this.filtersLoading = filtersLoading;
    this.setListLoadingFunction = setListLoading;
    this.setDetailsLoadingFunction = setDetailsLoading;
    this.setHideHeaderFunction = setHideHeader;
    this.setFiltersLoadingFunction = setFiltersLoading;
    this.router = router;
  }

  setListLoading(state) {
    if (!state) {
      setTimeout(() => {
        this.listLoading = state;
        this.setListLoadingFunction(this.listLoading);
      }, 5);
    } else {
      this.listLoading = state;
      this.setListLoadingFunction(this.listLoading);
    }
  }
  setDetailsLoading(state) {
    if (!state) {
      setTimeout(() => {
        this.detailsLoading = state;
        this.setDetailsLoadingFunction(this.detailsLoading);
      }, 4);
    } else {
      this.detailsLoading = state;
      this.setDetailsLoadingFunction(this.detailsLoading);
    }
  }
  setFiltersLoading(state) {
    if (!state) {
      setTimeout(() => {
        this.filtersLoading = state;
        this.setFiltersLoadingFunction(this.filtersLoading);
      }, 5);
    } else {
      this.filtersLoading = state;
      this.setFiltersLoadingFunction(this.filtersLoading);
    }
  }

  setListScrollDirection(direction) {
    if (this.lastDirection !== direction) {
      clearTimeout(this.directionChangeTimer);

      const delay = direction === 1 ? 200 : 2000;

      this.directionChangeTimer = setTimeout(() => {
        this.setHideHeaderFunction(direction === 1);
      }, delay);

      this.lastDirection = direction;
    }
  }

  addJobsToList = (jobs, addToTop = false, reset = false) => {
    if (reset) {
      this.data.jobsListData.jobsList = [];
    }
    if (addToTop) {
      this.data.jobsListData.jobsList = [
        ...jobs,
        ...this.data.jobsListData.jobsList,
      ];
    } else {
      this.data.jobsListData.jobsList = [
        ...this.data.jobsListData.jobsList,
        ...jobs,
      ];
    }
    this.data.jobsListData.canLoadMoreJobs =
      this.data.jobsListData.jobsList.length < this.data.jobsListData.totalJobs;
  };

  showJobDetails = async (job) => {
    try {
      this.data.jobDetailsData.jobDetails = job;
    } catch (error) {
      console.error("Error fetching job details:", error);
      throw error;
    }
  };

  getJobDetails = async (jobSlug) => {
    try {
      if (jobSlug) {
        this.setDetailsLoading(true);
        let selectedJob = await this.jobDetailsDataController.getJobDetails({
          jobSlug,
        });

        this.data.jobDetailsData.jobDetails = selectedJob?.jobDetails;
        this.jobListDataController.appendSelectedJobAndSimilarJobs();

        this.setDetailsLoading(false);
      }
      this.jobListDataController.checkedIfNeedToAddSimilarJobs = true;
    } catch (error) {
      console.error("Error fetching job details:", error);
      throw error;
    }
  };

  async getNextPageJobs() {
    if (this.data.jobsListData.canLoadMoreJobs) {
      this.setListLoading(true);
      this.data.jobsListData.page++;
      await this.jobListDataController.getPageJobs({
        ...this.jobFiltersDataController.updateDatePostedFilter(
          this.data.jobsFilterData.appliedFilters
        ),
        ...this.data.jobsFilterData.hiddenFilters,
      });
      this.setListLoading(false);
    }
  }

  clearFilters() {
    this.applyFiltersAndGetJobs({});
    this.jobFiltersDataController.clearFiltersFromFilterUI();
  }

  async applyFiltersAndGetJobs(filterQueryObj, removeSelectedJob = true) {
    this.setFiltersLoading(true);
    this.data.jobsFilterData.appliedFilters = filterQueryObj;
    this.data.jobsListData.page = 0;
    await this.jobListDataController.getPageJobs(
      {
        ...this.jobFiltersDataController.updateDatePostedFilter(
          this.data.jobsFilterData.appliedFilters
        ),
        ...this.data.jobsFilterData.hiddenFilters,
      },
      removeSelectedJob
    );
    this.jobFiltersDataController.removeJobQuery(this.router);
    this.setFiltersLoading(false);
  }

  async shortlistJob({ user_id, job_id, status }) {
    try {
      const data = await this.jobDetailsDataController.shortlistJob({
        user_id,
        job_id,
        status,
      });
      return data;
    } catch (error) {
      console.error("Error in shortlistJob:", error);
      throw error;
    }
  }
}

export default JobsSectionService;
