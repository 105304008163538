import ApolloClientManager from "@/Logic/GraphQL/ApolloClientManager";
import { GET_JOBS } from "@/Logic/GraphQL/Queries/Jobs.query";
import Constant from "@/staticData/constant";

class JobListDataController {
  #apolloClient;
  constructor(service) {
    this.timeout = null;
    this.checkShouldAddJobs = true;
    this.canAppendJobs = false;
    this.checkedIfNeedToAddSimilarJobs = false;
    this.service = service;
    this.#apolloClient = ApolloClientManager.client;
  }

  async #fetchJobs(filters, skip, limit) {
    try {
      const jobsData = await this.#apolloClient.query({
        query: GET_JOBS,
        variables: {
          filterObject: {
            filters,
            skip,
            limit,
          },
        },
      });

      return jobsData.data.jobs;
    } catch (err) {
      console.error(err);
      return null;
    }
  }

  async getPageJobs(filters = {}, removeSelectedJob = true) {
    if (this.service.data.jobsListData.page === 0) {
      this.service.data.jobsListData.jobListRef?.scrollTo(0, 0);
    }
    let currPage = this.service.data.jobsListData.page;
    let skip = currPage * Constant.PAGE_LIMIT;
    const jobsData = await this.#fetchJobs(filters, skip, Constant.PAGE_LIMIT);
    if (!jobsData) {
      return;
    }
    if (currPage === 0) {
      if (removeSelectedJob) {
        this.service.data.jobDetailsData.jobDetails = jobsData.firstJobDetails;
      } else {
        jobsData.dataList = [{ ...this.service.data.jobDetailsData.jobDetails }, ...jobsData.dataList]
      }
    }
    this.service.data.jobsListData.totalJobs = jobsData.count;
    this.service.addJobsToList(jobsData.dataList, false, currPage == 0);
  }

  highlightSimilarJobs({ jobsState }) {
    for (let index = 0; index < jobsState?.jobs?.length; index++) {
      let job = jobsState?.jobs[index];
      if (!job?.type || job.type !== "similar") {
        break;
      }
      const jobCard = document.getElementById(`job-card-${index}`);

      if (jobCard) {
        setTimeout(() => {
          jobCard.classList.add("isHighlighted");
        }, index * 400);
      }
    }

    if (highlightJob) {
      delete query.highlightJob;
    }
    this.service.router.push({ pathname: "/jobs", query });
  }

  clearStaticJob({ setJobsListState }) {
    setJobsListState({ staticJob: null });
  }

  shouldAppendJobs(staticJob) {
    if (
      typeof window !== "undefined" &&
      typeof sessionStorage !== "undefined" &&
      this.checkShouldAddJobs
    ) {
      this.checkShouldAddJobs = false;
      let url = window.location.href;

      this.canAppendJobs =
        !url.includes("jobs-") &&
        !url.includes("-jobs") &&
        (!staticJob?.slug_v2 == undefined || url.includes("job="));
    }
  }

  #updateJobsListWithSimilarJobs({ job }) {
    this.service.data.jobsListData.totalJobs =
      this.service.data.jobsListData.totalJobs + job?.similar_jobs?.length;
    this.service.addJobsToList(
      [
        job,
        ...job?.similar_jobs?.map((similarJob) => ({
          ...similarJob,
          type: "similar",
        })),
      ],
      true
    );
  }

  appendSelectedJobAndSimilarJobs() {
    if (!this.canAppendJobs) {
      return;
    }
    let job = {};
    if (typeof sessionStorage !== "undefined") {
      job = JSON.parse(sessionStorage.getItem("ftue3StaticJob"));
    }
    if (!job?.slug_v2) {
      job = this.service.data.jobDetailsData.jobDetails;
    }

    if (this.service.data.isCompanyPage) {
      this.service.addJobsToList(
        [this.service.data.jobDetailsData.jobDetails],
        true
      );
      this.canAppendJobs = false;
    } else {
      if (job?.slug_v2) {
        this.#updateJobsListWithSimilarJobs({
          job,
        });
        this.canAppendJobs = false;
      }
    }
  }
}

export default JobListDataController;
