import { DROPDOWN_SELECT } from "@/outscal-commons-frontend/FormBuilder/InputTypes";

const customData = {
  showSearchInput: true,
  isMultiSelect: true,
  primaryColor: "#067519",
  secondaryColor: "#e5f8e5",
};

const width = "220px";

const inputType = DROPDOWN_SELECT;

const commonData = {
  customData,
  width,
  inputType,
};

const sortByAlphabeticalOrder = (options = []) => {
  return options.sort((a, b) => {
    if (a.label < b.label) {
      return -1;
    }
    if (a.label > b.label) {
      return 1;
    }
    return 0;
  });
};

export const jobCategoryTypeFilterData = {
  jobCategoryType: [
    {
      id: "gaming",
      label: "Gaming",
    },
  ],
};

const getUserData = () => {
  let returnVal = {};
  if (typeof localStorage !== "undefined") {
    const userData = localStorage.getItem("userData");
    if (userData) {
      returnVal = JSON.parse(userData);
    }
  }
  return returnVal;
};

export const FiltersFormData = (FiltersOptionLists = {}) => {
  const userData = getUserData();

  const returnVal = {
    datePosted: {
      name: "datePosted",
      label: "Date",
      ...commonData,
      customData: {
        ...customData,
        showSearchInput: false,
        isMultiSelect: false,
      },
      width: "240px",
      optionsList: FiltersOptionLists.datePosted,
    },
    // categoryTags: {
    //   name: "categoryTags",
    //   label: "Category",
    //   ...commonData,
    //   width: "240px",
    //   optionsList: FiltersOptionLists.categoryTags,
    //   defaultList: FiltersOptionLists.categoryTags,
    //   filterAppliedLabel: "categories",
    // },
    location: {
      name: "location",
      label: "Location",
      ...commonData,
      width: "350px",
      optionsList: FiltersOptionLists.location,
      defaultList: sortByAlphabeticalOrder(
        FiltersOptionLists.location?.filter((location) =>
          DefaultLocationList.includes(location.id)
        )
      ),
      filterAppliedLabel: "locations",
    },
    skills: {
      name: "skills",
      label: "Skills",
      ...commonData,
      width: "200px",
      optionsList: FiltersOptionLists.skills,
      defaultList: sortByAlphabeticalOrder(
        FiltersOptionLists.skills?.filter((skill) =>
          DefaultSkillsList.includes(skill.id)
        )
      ),
      filterAppliedLabel: "skills",
    },
    experienceLevel: {
      name: "experienceLevel",
      label: "Experience",
      ...commonData,
      customData: { ...customData, showSearchInput: false },
      optionsList: FiltersOptionLists.experienceLevel,
    },
    companies: {
      name: "companies",
      label: "Company",
      ...commonData,
      customData: {
        width: "200px",
        ...customData,
        defaultLogo: "/assets/images/default_company.png",
      },
      optionsList: FiltersOptionLists.companies,
      defaultList: sortByAlphabeticalOrder(
        FiltersOptionLists.companies?.filter((company) =>
          DefaultCompaniesList.includes(company.id)
        )
      ),
      filterAppliedLabel: "companies",
    },
    locationType: {
      name: "locationType",
      label: "Remote",
      ...commonData,
      customData: { ...customData, showSearchInput: false },
      optionsList: FiltersOptionLists.locationType,
    },
    jobCategoryType: {
      name: "jobCategoryType",
      label: "Company Type",
      ...commonData,
      customData: { ...customData, showSearchInput: false },
      optionsList: FiltersOptionLists.jobCategoryType,
    },
    jobType: {
      name: "jobType",
      label: "Job Type",
      ...commonData,
      customData: { ...customData, showSearchInput: false },
      optionsList: FiltersOptionLists.jobType,
    },
  };

  return returnVal;
};

export const FilterData = {
  applyButton: "Apply",
  showFilterText: "Show Filters",
  totalResultsText: "Jobs",
  experienceLevel: [
    { id: "0-2", label: "0 - 2 years" },
    { id: "2-4", label: "2 - 4 years" },
    { id: "4-6", label: "4 - 6 years" },
    { id: "6+", label: "6+ years" },
  ],
  Ctc: [
    { id: "0-5", label: "0 - 5 LPA" },
    { id: "5-10", label: "5 - 10 LPA" },
    { id: "10-15", label: "10 - 15 LPA" },
    { id: "15-20", label: "15 - 20 LPA" },
    { id: "20-30", label: "20 - 30 LPA" },
    { id: "30+", label: "30+ LPA" },
  ],
  datePosted: [
    { id: "anytime", label: "Any Time" },
    { id: "past-24-hr", label: "Past 24 Hour" },
    { id: "past-week", label: "Past Week" },
    { id: "past-month", label: "Past Month" },
  ],
};

const DefaultCategoryList = [
  "2d-art",
  "3d-art",
  "animation",
  "game-ai",
  "game-programmer",
  "physics-engine",
  "game-production",
  "backend-development",
  "frontend-development",
  "full-stack-development",
  "mobile-development",
  "mobile-development",
  "systems-design",
  "technical-art",
  "graphics-engineer",
];

const DefaultCompaniesList = [
  "63bc242efa004246081b6465",
  "65992f66774eca8c6724759a",
  "638dfcfe3d7a823671110f20",
  "64fb33e8f0136ec51cff6bfe",
  "64d505c90984b0dc39134757",
  "64fb33e8f0136ec51cff6c1e",
  "64d505c90984b0dc3913475c",
  "638dfcfd3d7a823671110ead",
  "64d505c90984b0dc3913475d",
  "638dfcfd3d7a823671110ea0",
  "65216f043aeec6d74d48ed7b",
  "64a427adebbbcb057f488b30",
  "64d505c90984b0dc39134774",
  "64d505c90984b0dc3913475e",
  "64f9bb9c2b0ebfe5cbc547ee",
  "64d505c90984b0dc39134764",
  "64ddefc4904ed462bce32a14",
  "64d505c90984b0dc39134755",
  "63764874ef1d880305724393",
  "638dfcfd3d7a823671110e9d",
  "6555f6cc626210b0edaf4d64",
  "654a13a69b9197a3503946d3",
  "66c090f8e998490c40017768",
  "66914670aab343d9b20ed440",
];

const DefaultLocationList = [
  "ChIJkbeSa_BfYzARphNChaFPjNc",
  "ChIJkbeSa_BfYzARphNChaFPjNc",
  "ChIJYW1Zb-9kjEcRFXvLDxG1Vlw",
  "ChIJh5Z3Fw4gLG0RM0dqdeIY1rE",
  "ChIJURLu2YmmNBwRoOikHwxjXeg",
  "ChIJU1NoiDs6BIQREZgJa760ZO0",
  "ChIJzyjM68dZnAARYz4p8gYVWik",
  "ChIJA9KNRIL-1BIRb15jJFz1LOI",
  "ChIJi7xhMnjjQgwR7KNoB5Qs7KY",
  "ChIJ-1-U7rYnS0YRzZLgw9BDh1I",
  "ChIJ3fYyS9_KgUYREKh1PNZGAQA",
  "ChIJuwtkpGSZAEcR6lXMScpzdQk",
  "ChIJ38WHZwf9KysRUhNblaFnglM",
  "ChIJu-SH28MJxkcRnwq9_851obM",
  "ChIJ8fA1bTmyXEYRYm-tjaLruCI",
  "ChIJMVd4MymgVA0R99lHx5Y__Ws",
  "ChIJa76xwh5ymkcRW-WRjmtd6HU",
  "ChIJwULG5WSOUDERbzafNHyqHZU",
  "ChIJm7oRy-tVZDURS9uIugCbJJE",
  "ChIJLxl_1w9OZzQRRFJmfNR1QvU",
  "ChIJqZHHQhE7WgIReiWIMkOg-MQ",
  "ChIJ2WrMN9MDDUsRpY9Doiq3aJk",
  "ChIJCzYy5IS16lQRQrfeQ5K5Oxw",
];

const DefaultSkillsList = [
  "react",
  "c#",
  "cpp",
  "programming-patterns",
  "api-testing",
  "kubernetes",
  "testing",
  "data-structures",
  "algorithms",
  "mysql",
  "postgresql",
  "unreal",
  "unity",
  "3d-modeling",
  "adobe-animate",
  "vrar-testing",
  "motion-graphics",
  "agile-development",
  "storyboarding",
  "storytelling",
  "performance-testing",
];
