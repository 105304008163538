import React from "react";
import { UIElements } from "@/outscal-commons-frontend/Hooks/useAnalyticsClickEvent";
import Image from "next/image";
import styles from "./ImageTextCardC.module.css";

const ImageTextCardCView = ({
  analyticsName,
  viewAnalyticsName,
  heading,
  subHeading,
  plugImage,
  ctaText,
  textColor,
  bgColor,
  imageQuality,
  imageRTL,
}) => {
  return (
    <div
      id={viewAnalyticsName}
      className={styles.root}
      style={{
        "--bgColor": bgColor,
        "--textColor": textColor,
        flexDirection: `${imageRTL == true ? "row-reverse" : "row"}`,
      }}
      data-analytics={[UIElements.CARD, analyticsName]}
      view-analytics={[UIElements.CARD, viewAnalyticsName]}
      rel="nofollow"
    >
      <div className={styles.detailsWrap}>
        <div className={styles.header}>
          <h2 className={styles.heading} style={{ "--textColor": textColor }}>
            {heading}
          </h2>
          {subHeading && <p className={styles.subheading}>{subHeading}</p>}
        </div>
        <button
          className={styles.cta}
          data-analytics={[UIElements.BUTTON, `${analyticsName}-cta`]}
        >
          {ctaText}
        </button>
      </div>
      {plugImage && (
        <Image
          className={styles.image}
          src={plugImage}
          alt={analyticsName}
          width={120}
          height={120}
          quality={imageQuality}
        />
      )}
    </div>
  );
};

export default ImageTextCardCView;
