class JobsDataModel {
  constructor() {
    this.isCompanyPage = null;
    this.jobDetailsData = { jobDetails: {} };
    this.jobsPlugData = { assessmentsStrapiList: [], assessmentsDbList: [] };
    this.jobsListData = {
      totalJobs: 0,
      defaultJobsCount: 0,
      jobListRef: null,
      jobsList: [],
      interLinkings: {},
      jobPresets: [],
      canLoadMoreJobs: true,
      page: 0,
    };
    this.jobsFilterData = {
      appliedFilters: {},
      hiddenFilters: {},
      previousFiltersString: "",
    };
  }
}

export default JobsDataModel;