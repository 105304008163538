import React from "react";
import ImageTextCardCView from "./ImageTextCardC.view";
import { defaultProfilePics } from "@/staticData/constant";
import useUserActions from "@/store/actions/UserProfileActions/userAction";
import useJobQuestionsPopup from "@/mvcComponents/CommonUI/Hooks/useJobQuestionsPopup/useJobQuestionsPopup.controller";
import Link from "next/link";
import { useRouter } from "next/router";
import { themeColorsMap } from "@/outscal-commons-frontend/utils/commons";

const ImageTextCardC = ({
  analyticsName = "",
  heading = "",
  subHeading = "",
  image = {},
  ctaText = "",
  ctaLink = "",
  theme = "white",
  imageQuality = 80,
  imageRTL = false,
}) => {
  const router = useRouter();
  const { user } = useUserActions();
  const { filterProfilePicture } = useJobQuestionsPopup();

  const constructHref = () => {
    if (ctaLink.startsWith("/")) {
      return ctaLink;
    }

    const currentPath = router.asPath.split("?")[0];
    const currentQuery = router.asPath.split("?")[1];

    if (!currentQuery) {
      return `${currentPath}?${ctaLink}`;
    }

    return `${currentPath}?${currentQuery}&${ctaLink}`;
  };

  return (
    <Link
      href={constructHref()}
      id={analyticsName}
      rel="nofollow"
      style={{ width: "100%" }}
    >
      <ImageTextCardCView
        imageRTL={imageRTL}
        analyticsName={analyticsName}
        viewAnalyticsName={`${analyticsName}-view`}
        heading={heading}
        plugImage={
          image
            ? image === "default"
              ? filterProfilePicture(
                  user?.userDetails?.profile_picture,
                  defaultProfilePics
                )
              : image?.url
            : ""
        }
        subHeading={subHeading}
        ctaText={ctaText}
        textColor={themeColorsMap[theme]?.textColor}
        bgColor={themeColorsMap[theme]?.bgColor}
        imageQuality={imageQuality}
      />
    </Link>
  );
};

export default ImageTextCardC;
