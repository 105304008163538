import { getJobFilterQueryObjFromString } from "@/utils/urlHelper";
import { FiltersFormData } from "../JobFilterSection/JobFilters/JobFilter.model";

class JobFiltersDataController {
  constructor(service) {
    this.service = service;
    this.clearFiltersFromFilterPills = null;
    this.applyFiltersInUI = null;
  }

  hasFilterChanged(newFilters) {
    const oldString = JSON.stringify(
      this.service.data.jobsFilterData.appliedFilters
    );
    const newString = JSON.stringify(newFilters);
    return newString !== oldString;
  }

  removeJobQuery(router) {
    if (router.query.job) {
      delete router.query["job"];
    }
  }

  clearFiltersFromFilterUI() {
    if (this.clearFiltersFromFilterPills) {
      this.clearFiltersFromFilterPills();
    }
  }

  #getJobFiltersFromUrl(hiddenFilters = {}, filterOptions = undefined) {
    let url =
      typeof window != "undefined"
        ? window.location.href
        : "https://outscal.com/jobs";

    let queryObj = getJobFilterQueryObjFromString(url);
    if (filterOptions) {
      Object.keys(queryObj).forEach((filterKey) => {
        for (const filterItem of queryObj[filterKey]) {
          filterItem["label"] = filterOptions[filterKey].find((item) => {
            if (item.id === filterItem.id) {
              return item;
            }
          })?.label;
        }
      });
    }
    let filters = {};
    let savedFilters = {};
    if (
      hiddenFilters &&
      Object.keys(hiddenFilters).length &&
      !Object.keys(queryObj).length
    ) {
      let formatSavedFilters = {};
      if (
        savedFilters &&
        Object.keys(savedFilters).length &&
        !getQueryParam(url, "keyword")
      ) {
        formatSavedFilters = getFiltersFromQuery(savedFilters, filterOptions);
      }
      filters = { ...formatSavedFilters, ...hiddenFilters };
    } else {
      filters = queryObj;
    }

    return filters;
  }

  initializeFilters({ FiltersOptionLists }) {
    let filtersFormObj = FiltersFormData(FiltersOptionLists);

    let filters = this.#getJobFiltersFromUrl(undefined, FiltersOptionLists);
    let addFilters = this.fixedFilters || this.fixedJobFilters;
    if (addFilters) {
      if (Object.keys(addFilters).length) {
        Object.keys(addFilters).forEach((key) => {
          filters[key] = [...(filters[key] || []), ...addFilters[key]];
        });
      }
    }
    if (
      typeof window !== "undefined" &&
      window.location.href.includes("/company/")
    ) {
      delete filtersFormObj.companies;
    } else {
      if (!filtersFormObj.companies) {
        filtersFormObj.companies = FiltersOptionLists.companies || {};
      }
    }
    return { filters, filtersFormObj: { ...filtersFormObj } };
  }

  #getDateRangeFilter(key) {
    let dateRange = null;
    const now = new Date();
    if (key == "past-24-hr") {
      const past24Hr = new Date(now.getTime() - 24 * 60 * 60 * 1000);
      dateRange = `${past24Hr}-${now}`;
    } else if (key == "past-month") {
      const thirtyDaysBefore = new Date();
      thirtyDaysBefore.setDate(thirtyDaysBefore.getDate() - 30);
      dateRange = `${thirtyDaysBefore}-${now}`;
    } else if (key == "past-week") {
      const sevenDaysBefore = new Date();
      sevenDaysBefore.setDate(now.getDate() - 7);
      dateRange = `${sevenDaysBefore}-${now}`;
    }

    return dateRange;
  }

  updateDatePostedFilter(filterObj) {
    const updatedFilterObj = JSON.parse(JSON.stringify(filterObj));
    if (updatedFilterObj.datePosted && updatedFilterObj.datePosted.length > 0) {
      const dateFilter = updatedFilterObj.datePosted[0];
      const newDateRange = this.#getDateRangeFilter(dateFilter.id);
      if (newDateRange) {
        updatedFilterObj.datePosted[0].id = newDateRange;
      } else {
        delete updatedFilterObj.datePosted;
      }
      return updatedFilterObj;
    } else {
      return updatedFilterObj;
    }
  }
}

export default JobFiltersDataController;
