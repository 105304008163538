import ClientAppManager from "@/Logic/ClientLogic/Services/ClientAppManager";
import JobsSectionService from "../../../JobsData/JobsSection.service";

class JobsListPlugController {
  constructor() {
    this.filteredPlugsByAuth = [];
    this.selectedPlug = {};
  }

  #filterPlugsByAuth(plugs, auth) {
    return plugs?.filter((plug) => {
      const showOn = plug.showOn || [];

      if (auth?.token) {
        return (
          showOn.some((item) => item.slug === "loggedIn") || showOn.length === 0
        );
      } else {
        return (
          showOn.some((item) => item.slug === "loggedOut") ||
          showOn.length === 0
        );
      }
    });
  }

  #getEligiblePlugs(jobIndex, plugs) {
    return (
      plugs
        ?.filter((plug) => {
          const isEligible =
            (jobIndex % plug.interval === 0 ||
              jobIndex ===
                JobsSectionService?.instance?.data?.jobsListData?.totalJobs) &&
            !ClientAppManager.instance.jobPlugsManager.jobListViewedPlugs[
              plug?.interval
            ]?.includes(plug?.priority);
          return isEligible;
        })
        ?.sort((a, b) => b.priority - a.priority) || []
    );
  }

  #resetPlugs() {
    if (
      this.filteredPlugsByAuth?.length ===
      ClientAppManager.instance.jobPlugsManager.jobListViewedPlugs[
        this.selectedPlug?.interval
      ]?.length
    ) {
      ClientAppManager.instance.jobPlugsManager.jobListViewedPlugs[
        this.selectedPlug?.interval
      ] = [];
    }
  }

  getSelectedPlug(jobIndex, plugConfig, auth) {
    this.filteredPlugsByAuth = this.#filterPlugsByAuth(plugConfig, auth);
    const eligiblePlugs = this.#getEligiblePlugs(
      jobIndex,
      this.filteredPlugsByAuth
    );
    if (eligiblePlugs?.length === 0) {
      this.#resetPlugs();
      return null;
    }

    this.selectedPlug = eligiblePlugs[0];
    return this.selectedPlug;
  }
}

export default JobsListPlugController;
