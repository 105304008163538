import { useCallback } from "react";
import usePopupActions, { INPUT_FORM_POPUP, MESSAGE_POPUP } from "@/store/actions/popupAction";
import { jobQuestionsSchema, plugData } from "./useJobQuestionsPopup.model";
import { MdOutlineWatchLater } from "react-icons/md";

const useJobQuestionsPopup = () => {
  const { showPopup, hidePopups } = usePopupActions();

  const openJobQuestionnairePopup = useCallback(() => {
    showPopup(INPUT_FORM_POPUP, {
      heading: "",
      analyticsIntentText: plugData.analyticsIntentText,
      formBuilder: {
        formData: jobQuestionsSchema,
        submitText: "Submit",
        onSubmit: (e) => {
          window.open("/action-plan", "_blank");
          localStorage.setItem(
            "jobQuestionnairePlugResponse",
            JSON.stringify(e)
          );
          hidePopups();
        },
        allowSubmit: (formValues) => {
          const isFullTime =
            formValues?.work_experience === "full-time-gaming-industry";

          return (
            formValues?.work_experience &&
            formValues?.desired_role?.id &&
            formValues?.skill_self_rating &&
            formValues?.has_portfolio &&
            formValues?.resume_feedback &&
            (!isFullTime || formValues?.been_laid_off)
          );
        },
      },
    });
  }, [showPopup, hidePopups]);

  const openJobQuestionnaireWelcomePopup = useCallback(() => {
    showPopup(MESSAGE_POPUP, {
      title: plugData.heading,
      metaText: (
        <div style={{ display: "flex", gap: "2px", alignItems: "center" }}>
          <MdOutlineWatchLater style={{ marginTop: "1px" }} />
          <p>2 minutes</p>
        </div>
      ),
      ctaText: plugData.ctaText,
      messageColor: "textDarkerGrey",
      messageFontSize: "md",
      padding: "28px 40px",
      message: plugData.subHeading,
      analyticsText: plugData.analyticsIntentText,
      action: openJobQuestionnairePopup,
    });
  }, [showPopup, openJobQuestionnairePopup]);

  const filterProfilePicture = (link = "", excludedLinks = []) =>
    excludedLinks.includes(link) || !link
      ? "https://outscal-assets.s3.ap-south-1.amazonaws.com/course-detail-page/MayankGrover.png"
      : link;

  return { openJobQuestionnairePopup, openJobQuestionnaireWelcomePopup, filterProfilePicture };
};

export default useJobQuestionsPopup;
