import React, { useState, useEffect } from "react";
import JobsListPlugLogic from "./JobListPlugs.controller";
import { plugComponentsMap } from "./JobListPlugs.model";
import UseComponentVisibility from "@/hooks/useComponentVisibility";
import ImageTextCardC from "@/mvcComponents/CommonUI/Cards/ImageTextCardC/ImageTextCardC.controller";
import useAuthActions from "@/store/actions/authAction";
import ClientAppManager from "@/Logic/ClientLogic/Services/ClientAppManager";
const jobsListPlugLogic = new JobsListPlugLogic();

const JobListPlugs = ({ jobIndex, plugConfig }) => {
  const { auth } = useAuthActions();
  const [selectedPlug, setSelectedPlug] = useState(null);

  useEffect(() => {
    const plug = jobsListPlugLogic.getSelectedPlug(jobIndex, plugConfig, auth);
    setSelectedPlug(plug);
    if (plug) {
      ClientAppManager.instance.jobPlugsManager.addViewedPlug(
        plug.interval,
        plug.priority
      );
    }
  }, []);

  if (!selectedPlug) return null;

  const { type, props, showOn = [] } = selectedPlug;
  const { theme = "white" } = props;
  let Component = plugComponentsMap[type]?.component || ImageTextCardC;

  return !selectedPlug ? null : (
    <UseComponentVisibility conditions={{ data: showOn }}>
      <Component {...props} />
    </UseComponentVisibility>
  );
};

export default JobListPlugs;
